// prefix 'wy'

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    margin: 0;

    -webkit-appearance: none !important;
}

input:-webkit-autofill {
    background-color: #fff;
}

.ant-layout-content {
    min-height: calc(100vh - 133px) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #43bcb3;
    border-color: #43bcb3;
}

.car-web-container {
    overflow: hidden;

    // width: 100%;
    // height: 100%;
    div[data-reactroot] {
        // height: 100%;
    }

    .ant-layout {
        // height: 100%;
        // position: relative;
        // display: -webkit-box; /*解决锚点点击错位问题 */
        // display: -moz-box; /*解决锚点点击错位问题 */
    }

    .ant-layout-sider-collapsed {
        .logo {
            display: none;
        }
    }

    .ant-table {
        font-size: 14px;

        .ant-table-row {
            cursor: pointer;
        }
    }

    .ant-input-disabled {
        color: rgba(0, 0, 0, 0.85) // border-width: 0;
    }

    .ant-select-disabled {
        color: rgba(0, 0, 0, 0.85)
    }

    .ant-cascader-picker-disabled {
        color: rgba(0, 0, 0, 0.85)
    }

    .ant-badge {
        vertical-align: baseline;
    }

    .ant-spin-nested-loading {
        height: 100%;
    }
}

.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.wy-modal-form {
    padding: 10px 60px 0;

    .ant-row {
        // width: 90%;
    }
}

.wy-modal-form-repay {
    .ant-form-item {
        margin-bottom: 10px;
    }
}

.ant-modal-mask {
    background-color: transparent;
}

.wy-center-modal,
.ant-modal-wrap {
    background-color: rgba(55, 55, 55, .6);
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    background: rgb(240, 242, 245) !important;
    border-bottom: 1px solid #fff;

    // .userInfo {
    //     color: #fff;
    // }
}

.ant-table td {
    white-space: nowrap;
}

.ant-message {
    top: 25px;
}



.ant-layout-header {
    background: #fff !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
    // border-right: 3px solid #43bcb3 !important;
    border: none;
}

.ant-steps-item-process .ant-steps-item-icon,
.ant-steps-item-finish .ant-steps-item-icon {
    background: #43bcb3;
    border-color: #43bcb3;
}

.ant-steps-item-icon>.ant-steps-icon,
.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: #fff;
}

.ant-steps-item-finish>.ant-steps-item-content>.ant-steps-item-title::after {
    background: #43bcb3;
}

.ant-tabs-nav .ant-tabs-tab-active {
    color: #43bcb3;
}

.ant-tabs-ink-bar {
    background-color: #43bcb3;
}

.ant-tabs-tab:hover {
    color: #43bcb3;
}

.ant-layout-footer {
    background: #fff;
}

.ant-layout {
    min-height: 100% !important;
}
.ant-layout-sider-light{
    background: #fff;
}
.ant-menu-sub.ant-menu-inline{
    background: #fff !important;
}
.logoutIcon{
    color: #333 !important;
    margin-left: 24px;
    cursor: pointer;
    font-size: 14px;
    .anticon-logout{
        font-size: 16px;
        margin-right: 5px;
    }
}

.wy-form-title {
    display: flex;
    justify-content: flex-start;
    padding-left: 30px;
    background-color: #eee;
}

.wy-application-form {
    padding: 0 30px 10px;

    .ant-form-item-label {
        text-align: left;

        label {
            font-size: 14px;
        }
    }

    .wy-form-item {
        overflow: hidden;
        position: relative;
        margin-bottom: 30px;
        padding: 10px 30px;
        border-radius: 6px;
        background-color: #fff;

        h2 {
            margin-bottom: 30px;
            font-size: 20px;
            color: #707070;
            font-weight: bold;

            .wy-title-border {
                position: absolute;
                top: 18px;
                left: 0;
                height: 20px;
                border-left: 4px solid #4489ca;
            }
        }

        .wy-block-border {
            position: absolute;
            left: -50px;
            width: 150%;
            height: 2px;
            margin: -20px;
            background-color: #dcdcdc;
        }

        &:target {
            border: 1px solid #108ee9;
        }
    }

    .ant-row {
        float: left;
        width: 50%;
    }

}

.wy-business-modal {
    .wy-business-type {
        // text-align: center;
        padding-bottom: 20px;
        padding-left: 30px;

        p {
            margin: 30px auto;
            font-size: 18px;
        }
    }
}

.stepsHeader {
    height: 140px;
    background-color: #fff;

    .ant-steps {
        padding: 50px 15%;
    }
}

.steps-content {
    // overflow-x: auto;
    text-align: center;

    .uploadContainer {
        // height: 230px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .info {
            color: #f5222d;
            margin: 10px;
        }
    }
}

.steps-action {
    text-align: center;

}

.sendResultContainer {
    h1 {
        margin-bottom: 50px;
    }

    .wechatInfo {
        float: left;
        width: 50%;
        text-align: left;
    }

    // .wechatImg{

    // }
}

.historyContainer {
    margin: 0 auto;
    // padding-top: 50px;

    .historyDetail {
        margin-top: 20px;
    }
}

.helpContainer {
    width: 90%;
    margin: 0 auto;
    padding: 50px 0;

    img {
        margin: 20px 0;
    }

    span {
        color: red;
        font-size: 16px;
    }
}

.introContainer {
    // width: 90%;
    padding: 50px 8%;
    background: url('../images/fuwu.png') no-repeat;
    background-size: 100% 100%;

    // text-align: center;
    .ant-card-body {
        background: rgba(170, 245, 238, 0.4);
        min-height: 480px;
    }

    h1 {
        color: #fff;
        margin-bottom: 1.5em;
        letter-spacing: 8px;
    }

    .header {
        font-size: 18px;
        // font-weight: bold;
        color: #fff;

    }

    .title {
        margin: 80px 0;
    }

    .middle {
        font-size: 18px;
    }

    .right {
        font-size: 12px;
        text-align: left
    }

    .content {
        margin: 20px 0;

        .icon {
            img {
                width: 40px;
            }

            .smallTitle {
                display: inline-block;
                background: url('../images/box.png') no-repeat;
                background-size: contain;
                width: 112px;
                font-size: 18px;
                color: #fff;
                height: 40px;
                line-height: 40px;
                text-align: center;
            }
        }

        .text {
            margin-top: 20px;

            .dot {
                display: inline-block;
                width: 10px;
                height: 10px;
                // border-radius: 50%;
                background: #43bcb3;
                margin-right: 5px;
            }
        }

    }

}

.mapContainer {
    width: 1200px;
    // height: 100%;
    margin: 0 auto;
    padding-top: 5%;
    display: flex;
    justify-content: space-between;

    .textBox {
        width: 30%;
    }

    &>div {
        width: 50%;
    }

    .taobaoImg {
        display: flex;
        width: 400px;
        justify-content: space-between;
    }
}

.changeComModal {
    // height: 350px;
    text-align: center;

    .ant-modal-content {
        height: 100%;
    }
}

.manageContainer {
    .ant-card{
        min-height: 500px;
    }
}

.aboutContent {
    background: url('../images/aboutBg.png') no-repeat;
    background-size: cover;
}

.logo {
    width: 120px;
    height: 64px;
    background: url('../images/logo.png') no-repeat;
    background-size: contain;
    background-position-y: center;
    // margin: 16px 24px 16px 0;
    float: left;
}

.homeContainer {
    // width: 1000px;
    // height: 420px;
    margin: 100px 15% 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .login-content {
        width: 400px;
        height: 350px;
        padding: 20px 40px;
        border: 10px solid #fff;
        border-radius: 10px;
        background-color: #fff;

        .login-form {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p {
                text-align: center;
            }
        }

        .login-form-button {
            // width: 100%;
            float: right;
        }
    }

}

.bottomContainer {
    font-size: 20px;
    // color: black;
    width: 1000px;
    height: 140px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.registerContainer {
    .register-form {
        width: 680px;
        height: 500px;
        margin: 16px auto 0;
        padding: 20px 40px;
        border-radius: 10px;
        background-color: #fff;
        margin: 0 auto;
        margin-top: 60px;
        .login-form-button {
            width: 100%;
        }
        h1{
            margin: 24px 0;
        }
        p,h1 {
            text-align: center;
        }

        .ant-form-explain {
            text-align: left;
        }
    }

}

.forgot-content {
    width: 550px;
    height: 400px;
    padding: 20px 40px;
    margin: 50px auto;
    border: 10px solid #fff;
    border-radius: 10px;
    background-color: #fff;
    // text-align: center;
    h1{
        text-align: center;
        margin-bottom: 24px;
    }
    .fogot-button{
        margin-top: 12px;
    }
}
.addRequiredIcon {
    .ant-form-item-label>label::before {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }
}

.addContainer {
    width: 600px;
    padding: 80px 40px !important;
    margin: 0 auto !important;
    background-color: #fff;
}

.agreeModal {
    width: 70% !important;

    // height: 75%;
    // overflow: auto;
    h3 {
        font-weight: bold;
    }
}
.payPage{
    .block{
        margin-bottom: 1.75rem;
        // background: #f0f3f8 !important;
        box-shadow: 0 1px 3px rgb(228 228 228 / 50%),
        0 1px 2px rgb(228 228 228 / 50%);
    }
    .block-rounded {
        border-radius: 0.25rem;
    }
    .block-header {
        background-color: #fafafa;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.75rem 1.25rem;
        transition: opacity .25s ease-out;
    }
    .block-title {
        flex: 1 1 auto;
        min-height: 1.75rem;
        margin: 0;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.75;
    }
    .block-content {
        transition: opacity .25s ease-out;
        width: 100%;
        margin: 0 auto;
        padding: 1.25rem 1.25rem 1px;
        overflow-x: visible;
    }
    .block.block-rounded>.block-content:last-child {
        border-bottom-right-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
    }
    .p-0 {
        padding: 0 !important;
    }
    .v2board-select {
        border: 1px solid #eee;
        padding: 20px;
        display: flex;
        font-size: 16px;
        cursor: pointer;
    }
    .active {
        border-color: #43bcb3 !important;
        border-bottom: unset;
        border-style: solid;
        border-radius: 5px;
        border-width: 2px;
        // margin: -2px -2px -1px;
    }
    .border-primary {
        border-color: #43bcb3 !important;
    }
}
@primary-color: #43bcb3;